import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const getData = graphql`
  query {
    site {
      siteMetadata {
        siteTitle: title
        siteDesc: description
        siteImage: image
        url
      }
    }
  }
`;

const SEO = ({ title, description, image }) => {
  const { site } = useStaticQuery(getData);
  console.log(site);
  const { siteDesc, siteTitle, url, siteImage } = site.siteMetadata;
  return (
    <Helmet htmlAttributes={{ lang: "en" }} title={siteTitle}>
      <meta name="description" content={description || siteDesc} />
      <meta name="image" content={image || siteImage} />

      {/* Facebook Card */}
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={siteTitle} />
      <meta property="og:description" content={description || siteDesc} />
      <meta property="og:image" content={`${url}/${image || siteImage}`} />
      <meta property="og:image" content="400" />
      <meta property="og:image:height" content="300" />

      {/* Twitter Card */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={siteTitle} />
      <meta name="twitter:description" content={description || siteDesc} />
      <meta name="twitter:image" content={`${url}/${image || siteImage}`} />
    </Helmet>
  );
};

export default SEO;
